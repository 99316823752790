export const skill = [
  {
    title: 'Hard',
    description: 'My technical skills include...',
    skills: [
      'VUE.js',
      'Angular',
      'React',
      'TypeScript',
      'JavaScript',
      'HTML',
      'CSS3',
      'BEM',
      'Less',
      'SASS',
      'Bootstrap',
      'GitHub',
      'GitLab',
      'Node',
      'npm',
      'Yarn',
      'Jest',
      'Testing Library',
      'Storybook',
      'Jenkins',
      'GitHub Actions',
      'Doker',
      'AWS',
      'Prototyping',
      'ESLint',
      'Prettier',
    ],
  },
  {
    title: 'Soft',
    description: 'My personal skills include...',
    skills: [
      'Executor',
      'Communicative',
      'Planner',
      'Analyst',
      'Committed',
      'Teamwork',
      'Non-stop learning',
      'Open-minded',
      'Proactive',
      'Patient',
      'Organized',
    ],
  },
  {
    title: 'Differentials',
    description: 'My work differentials skills include...',
    skills: [
      'Agile Methodologies',
      'Clean Code',
      'Technical Lead',
      'Project Management',
      'SEO',
      'Accessibility',
    ],
  },
  {
    title: 'Idiomas',
    description: 'My language skills include...',
    skills: [
      'Portuguese - Native',
      'English - Intermediate',
      'Spanish - Beginner',
    ],
  },
]
